const initialState = {
  allCountryListing: [],
  countryDetails: [],
  deleteCountry: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COUNTRIES":
      return { ...state, allCountryListing: action.payload };
    case "GET_SINGLE_COUNTRY_DETAIL": {
      return { ...state, countryDetails: action.payload };
    }
    case "CLEAN_COUNTRY_MASTER":
      return { ...state, countryDetails: initialState.countryDetails };
      case "DELETE_COUNTRY":
        return { ...state, deleteCountry: action.payload ? true : false };
    default:
      return { ...state };
  }
};
